import Vue from 'vue';
import {
  apiReadCSSAccounts,
  apiStartMimic,
  apiStopMimic,
  apiAddNote,
  apiUpdateEmail,
  apiChangeOrganizationOwnership,
  apiResetPassword,
  apiGetUserList,
  apiGetApproverList,
  apiGetSenderList,
  apiGetPossibleDeletionReasons,
  apiGetPossibleDowngradeReasons,
  apiResetInvitation,
  apiDeleteInvitation,
  apiReadMarketingCampaignsWithInvites,
  apiReadMarketingCampaignsWithAccounts,
} from '@/api/cssModule';
import { datadogLogs } from '@datadog/browser-logs';

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    accounts: [],
    invitations: [],
    totalInvitations: 0,
    tableOptions: {},
    totalItems: 0,
    marketingCampaigns: {},
    userList: [],
    approverList: [],
    senderList: [],
    deletionReasons: [],
    abortController: null,
    downgradeReasons: [],
    promises: [],
    currentPromise: null,
    isResettingInvite: false,
    isResendingActivation: false,
    isDeletingInvite: false,
    campaignsLoading: false,
  }),

  mutations: {
    addPromise(state, promise) {
      state.promises = [promise, ...state.promises];
    },

    removePromise(state, promise) {
      state.promises = state.promises.filter((p) => p !== promise);
    },

    setCurrentPromise(state, promise) {
      state.currentPromise = promise;
    },

    setAbortController(state, value) {
      state.abortController = value;
    },

    setLoading(state, value) {
      state.loading = value;
    },

    setCampaignLoading(state, value) {
      state.campaignsLoading = value;
    },

    setAccounts(state, value) {
      state.accounts = value;
    },

    setInvitations(state, value) {
      state.invitations = value;
    },

    setMarketingCampaigns(state, value) {
      state.marketingCampaigns = value.reduce((obj, item) => Object.assign(obj, { [item.id]: item }), {});
    },

    setTotalInvitations(state, value) {
      state.totalInvitations = value;
    },

    setTableOptions(state, value) {
      state.tableOptions = value;
    },

    setTotalItems(state, value) {
      state.totalItems = value;
    },

    setUserList(state, value) {
      state.userList = value;
    },

    setApproverList(state, value) {
      state.approverList = value;
    },

    setSenderList(state, value) {
      state.senderList = value;
    },

    setPossibleDeletionReasons(state, value) {
      state.deletionReasons = value;
    },

    setPossibleDowngradeReasons(state, value) {
      state.downgradeReasons = value;
    },
  },

  getters: {
    campaignName: (state) => (id) => state.marketingCampaigns[id]?.name,
    distinctCampaigns: (state) => {
      const campaignsMap = {};
      Object.values(state.marketingCampaigns).forEach((campaign) => {
        const key = campaign.name.toLowerCase().trim();
        const entry = campaignsMap[key] ?? {
          count: 0,
          prettyName: campaign.name,
          campaigns: [],
        };
        entry.campaigns.push(campaign);
        entry.count += campaign.entry_count;
        campaignsMap[key] = entry;
      });
      return campaignsMap;
    },
  },

  actions: {
    async startMimic({ dispatch, state, commit }, account) {
      if (state.promises.length > 0) {
        const newPromises = state.promises.map((promise) => promise.catch((e) => {
          datadogLogs.logger.error('Error starting mimic', { account: account.id }, e);
        }));
        await Promise.all(newPromises);
      }
      commit('app/setIsChangingMimic', true, { root: true });
      await dispatch('makeAPICall', {
        item: account,
        apiFunction: apiStartMimic,
        onSuccess: () => {
          window.location.href = '/';
        },
      });
    },

    async addNote({ dispatch }, { account, text }) {
      await dispatch('makeAPICall', {
        item: {
          id: account.id,
          text,
        },
        apiFunction: apiAddNote,
        onSuccess: () => { },
      });
    },

    async stopMimic({ dispatch, state, commit }, force = false) {
      commit('app/setIsChangingMimic', true, { root: true });
      await dispatch('makeAPICall', {
        item: force,
        apiFunction: apiStopMimic,
        onSuccess: () => {
          window.location.href = '/account/css_module/accounts/';
        },
      });
    },

    async updateEmail({ dispatch, state }, { accountID, email }) {
      await dispatch('makeAPICall', {
        item: {
          account: accountID,
          email,
        },
        apiFunction: apiUpdateEmail,
        onSuccess: () => {
          const accounts = Object.values(state.accounts?.accounts) ?? [];
          const updatedAccount = accounts.find((account) => account.id === accountID);
          updatedAccount.email = email;
        },
      });
    },

    async changeOrganizationOwnership({ dispatch, state, commit }, { accountID, organizationID }) {
      await dispatch('makeAPICall', {
        item: {
          account: accountID,
          organization: organizationID,
        },
        apiFunction: apiChangeOrganizationOwnership,
        onSuccess: () => {
          const organizationIndex = state.accounts['organizations'].findIndex((organization) => organization.organization_id === organizationID);
          const newOwner = state.accounts['accounts'][accountID];

          const accountsObject = state.accounts;

          accountsObject['organizations'][organizationIndex]['owner'] = newOwner.id;
          commit('setAccounts', accountsObject);
        },
      });
    },

    async resetPassword({ dispatch }, account) {
      await dispatch('makeAPICall', {
        item: account,
        apiFunction: apiResetPassword,
        onSuccess: () => { },
      });
    },

    async makeAPICall({ commit }, { item, apiFunction, onSuccess }) {
      try {
        commit('setLoading', true);
        const message = item !== null ? await apiFunction(item) : await apiFunction();
        this._vm.$snackbar.success(message);
        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setLoading', false);
      }
    },

    async readInvitations(
      { commit },
      { marketingCampaigns, searchParam, paginationOptions },
    ) {
      try {
        commit('setLoading', true);
        const { results, total } = await API.readInvitations(marketingCampaigns, searchParam, paginationOptions);
        commit('setInvitations', results);
        commit('setTotalInvitations', total);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setLoading', false);
      }
    },

    async readMarketingCampaigns(
      { commit },
    ) {
      try {
        commit('setCampaignLoading', true);
        const data = await API.apiReadMarketingCampaigns();
        commit('setMarketingCampaigns', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setCampaignLoading', false);
      }
    },

    async readMarketingCampaignsWithInvites(
      { commit },
    ) {
      try {
        commit('setCampaignLoading', true);
        const data = await apiReadMarketingCampaignsWithInvites();
        commit('setMarketingCampaigns', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setCampaignLoading', false);
      }
    },

    async readMarketingCampaignsWithAccounts(
      { commit },
    ) {
      try {
        commit('setCampaignLoading', true);
        const data = await apiReadMarketingCampaignsWithAccounts();
        commit('setMarketingCampaigns', data);
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        commit('setCampaignLoading', false);
      }
    },

    async readAccounts({ commit, state }, filters) {
      try {
        commit('setLoading', true);
        const options = { ...state.tableOptions, ...filters };
        const promise = apiReadCSSAccounts(options);
        commit('addPromise', promise);
        commit('setCurrentPromise', promise);
        const { results, total, message } = await promise;
        commit('removePromise', promise);
        if (state.currentPromise !== promise) {
          // new promise added
          return;
        }
        commit('setCurrentPromise', null);
        if (results && !message) {
          commit('setAccounts', results);
          commit('setTotalItems', total);
        } if (message) {
          this._vm.$snackbar.error(message);
        }
      } catch (e) {
        if (!(e instanceof DOMException && e.name === 'AbortError')) {
          this._vm.$snackbar.error(e);
        }
      } finally {
        commit('setLoading', false);
      }
    },

    changeTableOptions({ commit }, options) {
      commit('setTableOptions', options);
    },

    async readUserList({ commit }) {
      try {
        const result = await apiGetUserList();
        commit('setUserList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readApproverList({ commit }) {
      try {
        const result = await apiGetApproverList();
        commit('setApproverList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readSenderList({ commit }) {
      try {
        const result = await apiGetSenderList();
        commit('setSenderList', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readPossibleDeletionReasons({ commit }) {
      try {
        const result = await apiGetPossibleDeletionReasons();
        commit('setPossibleDeletionReasons', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async readPossibleDowngradeReasons({ commit }) {
      try {
        const result = await apiGetPossibleDowngradeReasons();
        commit('setPossibleDowngradeReasons', result);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async resetInvitation({ state }, invitation) {
      try {
        state.isResettingInvite = true;
        await apiResetInvitation({
          email: invitation.email,
          invitation: invitation.id,
        });
        this._vm.$snackbar.success('Invitation resent successfully');
      } catch (e) {
        this._vm.$snackbar.error(`Invitation failed to resend for: ${e.email}`);
      } finally {
        state.isResettingInvite = false;
      }
    },

    async resendActivation({ state }, user) {
      try {
        state.isResendingActivation = true;
        await apiResetInvitation({
          email: user.email,
        });
        this._vm.$snackbar.success('Invitation resent successfully');
      } catch (e) {
        this._vm.$snackbar.error(`Invitation failed to resend for: ${e.email}`);
      } finally {
        state.isResendingActivation = false;
      }
    },

    async deleteInvitation({ state }, invitation) {
      try {
        state.isDeletingInvite = true;
        await apiDeleteInvitation({
          email: invitation.email,
        });
        this._vm.$snackbar.success('Invitation deleted successfully');
      } catch (e) {
        this._vm.$snackbar.error(e);
      } finally {
        state.isDeletingInvite = false;
      }
    },
  },
};
