import { SCOPE_SMART_ADVISOR } from './scopes';

// ROI Calculator
const ROICalculatorPage = () => import('@/pages/budgeting/ROICalculatorPage.vue');
const ExpensesPage = () => import('@/pages/budgeting/ExpensesPage.vue');
const OverviewPage = () => import('@/pages/budgeting/OverviewPage.vue');

// Cash Flow
const CashFlow = () => import('@/pages/budgeting/CashFlow.vue');
const RiskProfile = () => import('@/pages/budgeting/RiskProfile.vue');

// AI Advisor
const AIAdvisor = () => import('@/pages/ai_advisor/AIAdvisor.vue');

// Scenario Planner
const ScenarioPlanner = () => import('@/pages/budgeting/ScenarioPlanner.vue');

// Buyer Smart Advisor
const BuyerSmartAdvisor = () => import('@/pages/budgeting/BuyerSmartAdvisor.vue');

const routes = [
  {
    path: '/budgeting/roi_calculator/',
    meta: {
      id: 'ROICalculator',
      title: 'ROI Calculator',
    },
    component: ROICalculatorPage,
    children: [
      {
        path: '',
        redirect: { name: 'ROIExpenses' },
      },
      {
        path: 'expenses/',
        name: 'ROIExpenses',
        component: ExpensesPage,
        meta: {
          title: 'Expenses',
          permission: {
            scope: 'ROI_calculator',
            permission: 'read',
          },
        },
        props: ((route) => ({
          openCropAddDialog: route.params.openCropAddDialog,
          cropYear: route.params.cropYear,
        })),
      },
      {
        path: 'overview/',
        name: 'ROIOverview',
        component: OverviewPage,
        meta: {
          title: 'Overview',
          permission: {
            scope: 'ROI_calculator',
            permission: 'read',
          },
        },
      },
    ],
  },
  {
    path: '/budgeting/cash_flow_planner/',
    meta: {
      title: 'Cash Flow Planner',
    },
    component: CashFlow,
  },
  {
    path: '/budgeting/risk_profile/',
    meta: {
      title: 'Risk Profile',
    },
    component: RiskProfile,
  },
  {
    path: '/budgeting/ai_advisor/',
    name: SCOPE_SMART_ADVISOR,
    meta: {
      title: 'Smart Advisor',
    },
    component: AIAdvisor,
  },
  {
    path: '/budgeting/scenario_planner/',
    meta: {
      title: 'Scenario Planner',
    },
    component: ScenarioPlanner,
  },
  {
    path: '/budgeting/buyer_smart_advisor/',
    meta: {
      title: 'Buyer Smart Advisor',
    },
    component: BuyerSmartAdvisor,
  },
];

export default routes;
