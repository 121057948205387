import Vue from 'vue';
import {
  apiCreateCashFlowGoal,
  apiUpdateCashFlowGoal,
  apiDeleteCashFlowGoal,
  apiCreateCashFlowGoalProgress,
  apiUpdateCashFlowGoalProgress,
  apiDeleteCashFlowGoalProgress,
} from '@/api/cashflowGoals';

import { getDay } from '@/helpers/datetime';

export default {
  state: () => ({
    cashFlowGoals: [],
    cashFlowGoal: null,
    isEditMode: false,
  }),

  getters: {
    getCashFlowGoalById: (state) => (id) => state.cashFlowGoals.find((goal) => goal.id === id),
    hasGoal: (state) => state.cashFlowGoals.length > 0,
    getCashFlowGoalProgressSum: (state, getters) => (id, checkDate = true) => {
      const today = new Date();
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        const progress_sum = goal.progress.filter((prog) => (getDay(prog.payment_date) <= today) || !checkDate).reduce((acc, cur) => acc + Number(cur.amount), 0);
        const contract_sum = goal.contracts.filter((contract) => (getDay(contract.date) <= today) || !checkDate).reduce((acc, cur) => acc + Number(cur.price), 0);
        return progress_sum + contract_sum;
      }
      return 0;
    },
    getCashFlowGoalTotalPercent: (state, getters) => (id) => {
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        if (+goal.target <= 0) {
          return 100;
        }
        return Math.max(0, Math.min(100, (getters.getCashFlowGoalTotalAmount(id) / Number(goal.target)) * 100));
      }
      return 0;
    },
    getCashFlowGoalTotalAmount: (state, getters) => (id) => {
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        return Math.min(getters.getCashFlowGoalProgressSum(id, false), Number(goal.target));
      }
      return 0;
    },
    getCashFlowGoalCompletePercent: (state, getters) => (id) => {
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        if (+goal.target <= 0) {
          return 100;
        }
        return Math.max(0, Math.min(100, (getters.getCashFlowGoalCompletedAmount(id) / Number(goal.target)) * 100));
      }
      return 0;
    },
    getCashFlowGoalCompletedAmount: (state, getters) => (id) => {
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        return Math.min(getters.getCashFlowGoalProgressSum(id), Number(goal.target));
      }
      return 0;
    },
    completedGoals(state, getters) {
      return state.cashFlowGoals.filter((goal) => (
        getters.getCashFlowGoalProgressSum(goal.id) >= Number(goal.target)
      ));
    },
    expiredGoals(state, getters) {
      return state.cashFlowGoals.filter((goal) => (
        getters.getCashFlowGoalProgressSum(goal.id) < Number(goal.target)
        && new Date(goal.date) < new Date()
      ));
    },
    inProgressGoals(state, getters) {
      return state.cashFlowGoals.filter((goal) => (
        getters.getCashFlowGoalProgressSum(goal.id) < Number(goal.target)
        && new Date(goal.date) > new Date()
      ));
    },
    getCashFlowGoalProgress: (state, getters) => (id, checkDate = true) => {
      const today = new Date();
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        const progress_sum = goal.progress.filter((prog) => (getDay(prog.payment_date) <= today) || !checkDate).reduce((acc, cur) => acc + Number(cur.amount), 0);
        const contract_sum = goal.contracts.filter((contract) => (getDay(contract.date) <= today) || !checkDate).reduce((acc, cur) => acc + Number(cur.price), 0);
        return progress_sum + contract_sum;
      }
      return 0;
    },
    getCashFlowGoalExpectedProgress: (state, getters) => (id, checkDate = true) => {
      const today = new Date();
      const goal = getters.getCashFlowGoalById(id);
      if (goal) {
        const progress_sum = goal.progress.filter((prog) => (getDay(prog.payment_date) > today) || !checkDate).reduce((acc, cur) => acc + Number(cur.amount), 0);
        const contract_sum = goal.contracts.filter((contract) => (getDay(contract.date) > today) || !checkDate).reduce((acc, cur) => acc + Number(cur.price), 0);
        return progress_sum + contract_sum;
      }
      return 0;
    },
    cashFlowGoalWithState(state, getters) {
      const goals = state.cashFlowGoals.map((goal) => {
        const goalProgress = getters.getCashFlowGoalProgress(goal.id);
        const goalExpectedProgress = getters.getCashFlowGoalExpectedProgress(goal.id);
        const totalProgress = goalProgress + goalExpectedProgress;
        const goalTarget = Number(goal.target);
        const goalDate = new Date(goal.date);
        const currentDate = new Date();
        let goalState;

        if (goalProgress < goalTarget && currentDate > goalDate) {
          goalState = 'expired';
        } else if (goalProgress >= goalTarget) {
          goalState = 'completed';
        } else if (totalProgress === 0) {
          goalState = 'started';
        } else if (totalProgress >= goalTarget) {
          goalState = 'awaiting-payment';
        } else {
          goalState = 'in-progress';
        }

        return {
          ...goal,
          state: goalState,
          expectedPayment: getters.getCashFlowGoalCompletedAmount(goal.id) === goalTarget ? 0 : goalExpectedProgress,
        };
      });
      return goals[0];
    },

    getCashFlowStateNames() {
      return {
        started: 'Started',
        'in-progress': 'In Progress',
        'awaiting-payment': 'Awaiting Payment',
        completed: 'Completed',
        expired: 'Expired',
      };
    },

    getCashFlowStateInformation() {
      return {
        started: 'The cash flow goal has been created and is now active. Start tracking progress to meet your financial targets.',
        'in-progress': 'Activities or transactions related to this goal are ongoing. Keep monitoring to ensure you stay on track.',
        'awaiting-payment': 'Payment is pending for a transaction associated with this goal. Follow up to avoid delays.',
        completed: 'Congratulations! This cash flow goal has been successfully achieved.',
        expired: 'The deadline for this cash flow goal has passed without completion. Consider revisiting and creating a new plan.',
      };
    },

  },

  mutations: {
    setCashFlowGoals(state, cashFlowGoals) {
      if (cashFlowGoals) {
        state.cashFlowGoals = Object.values(cashFlowGoals);
      }
    },
    // Temporary Getter to support old Cash Flow Goal until new one finished implementation
    // Remove this and all mentions of this mutation and cashflowGoal state variable once the epic
    // is finished
    setCashFlowGoal(state, cashFlowGoal) {
      state.cashFlowGoal = cashFlowGoal;
    },

    addCashFlowGoalProgress(state, { goal, cashFlowGoalProgress }) {
      state.cashFlowGoals.find((g) => g.id === goal).progress.push(cashFlowGoalProgress);
    },

    updateCashFlowGoalProgress(state, { goal, cashFlowGoalProgress }) {
      const goalObject = state.cashFlowGoals.find((g) => g.id === goal);
      const index = goalObject.progress.findIndex((p) => p.id === cashFlowGoalProgress.id);
      Vue.set(goalObject.progress, index, cashFlowGoalProgress);
    },

    deleteCashFlowGoalProgress(state, { goal, cashFlowGoalProgressId }) {
      const goalObject = state.cashFlowGoals.find((g) => g.id === goal);
      const index = goalObject.progress.findIndex((p) => p.id === cashFlowGoalProgressId);
      Vue.delete(goalObject.progress, index);
    },

    setIsEditMode(state, isEditMode) {
      state.isEditMode = isEditMode;
    },
  },

  actions: {
    async createCashFlowGoal({ commit }, payload) {
      try {
        const { data, message } = await apiCreateCashFlowGoal(payload);
        commit('setCashFlowGoal', data);
        commit('setCashFlowGoals', [data]);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error('Unable to create cash flow goal.');
      }
    },

    async updateCashFlowGoal({ commit }, payload) {
      try {
        const { data, message } = await apiUpdateCashFlowGoal(payload);
        commit('setCashFlowGoal', data);
        commit('setCashFlowGoals', [data]);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error('Unable to update cash flow goal.');
      }
    },

    async deleteCashFlowGoal({ commit, state, getters }, id) {
      try {
        const { message } = await apiDeleteCashFlowGoal(id);

        const newGoals = state.cashFlowGoals.filter((goal) => goal.id !== id);

        commit('setCashFlowGoals', newGoals);

        commit('setCashFlowGoal', null);
        this._vm.$snackbar.success(message);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async createCashFlowGoalProgress({ commit }, payload) {
      try {
        const { data, message } = await apiCreateCashFlowGoalProgress(payload);
        commit('addCashFlowGoalProgress', { goal: payload.goal, cashFlowGoalProgress: data });
        return true;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return false;
      }
    },

    async updateCashFlowGoalProgress({ commit }, payload) {
      try {
        const { data, message } = await apiUpdateCashFlowGoalProgress(payload);
        commit('updateCashFlowGoalProgress', { goal: payload.goal, cashFlowGoalProgress: data });
        return data;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return null;
      }
    },

    async deleteCashFlowGoalProgress({ commit }, payload) {
      try {
        const { message } = await apiDeleteCashFlowGoalProgress(payload.id);
        commit('deleteCashFlowGoalProgress', { goal: payload.goal, cashFlowGoalProgressId: payload.id });
        return true;
      } catch (e) {
        this._vm.$snackbar.error(e);
        return false;
      }
    },
  },

  namespaced: true,
};
