<template>
  <component
    :is="component"
    v-model="dialogOpen"
    class="px-3"
    title="Commodities of Interest"
    disable-fullscreen
    :width="580"
    :action-buttons="CommoditiesOfInterestActionButtons"
  >
    <p
      v-if="!fromDashboard"
      class="text-body-1 ma-0 black--text"
    >
      Crop Interests
    </p>
    <p
      v-if="fromDashboard"
      class="text-caption grey--text mb-2"
    >
      Click one or more commodities below to select them
    </p>
    <p
      v-else
      class="text-caption grey--text mb-2"
    >
      Click one or more crops below to select them
    </p>
    <v-form
      ref="cropInterestField"
      :class="!$isMobile ? 'd-flex flex-column fixed-form' : ''"
      validate
    >
      <v-combobox
        v-model="selectedCommodities"
        chips
        multiple
        readonly
        outlined
        required
        :type="selectedCommodities.length ? 'hidden' : null"
        :placeholder="fromDashboard ? 'Commodity Interests' : 'Crop Interests'"
        :rules="comboBoxRules"
        class="combobox"
        :class="{'capped': showAllCrops && !$isMobile }"
        append-icon=""
      >
        <template #selection="{ item, selected }">
          <v-chip
            :small="!$isMobile"
            :class="{'mr-2 my-2': $isMobile} "
            :input-value="selected"
            close
            @click="deselectCommodity(item)"
            @click:close="deselectCommodity(item)"
          >
            {{ item.name }}
          </v-chip>
        </template>
      </v-combobox>

      <div
        ref="desktopChipRow"
        :class="{'desktop-row-max' : toggleMoreButton && !$isMobile && !showAllCrops,
                 'desktop-row-fixed': !$isMobile && showAllCrops && commodityBank.length }"
      >
        <div
          ref="mobileChipRow"
          :class="{'mobile-row-max' : $isMobile}"
        >
          <v-chip
            v-for="(commodity, rank) in commodityBank"
            :key="rank"
            :small="!$isMobile"
            class="my-1 mx-1"
            :class="{'mr-2 mt-2 mb-2' : $isMobile}"
            @click="selectCommodity(commodity)"
          >
            {{ commodity.name }}
          </v-chip>
        </div>
      </div>
      <!-- For when user clicks "read more" -->
      <div>
        <v-btn
          v-if="toggleMoreButton && ($isMobile || !showAllCrops) && displayRowExpansion"
          text
          class="primary-grey--text font-weight-regular"
          :class="$isMobile ? 'mt-3' : 'mt-5'"
          @click="$isMobile ? openMobileShowMore() : toggleMore()"
        >
          View More Crops
        </v-btn>
      </div>

      <div v-if="!showAllCrops">
        <v-btn
          v-if="!toggleMoreButton && displayRowExpansion"
          text
          class="primary-grey--text font-weight-regular mt-5"
          @click="toggleLess"
        >
          View Less Crops
        </v-btn>
      </div>

      <!-- Mobile Logic on Show More -->
      <g-dialog
        v-model="mobileShowMore"
        title="Crop Interests"
        disable-fullscreen
        :action-buttons="actionButtons"
      >
        <p>Select some crops</p>
        <v-list-item
          v-for="commodity in commodityBank"
          :key="commodity.rank"
          class="my-n2 pl-2"
        >
          <v-checkbox
            v-model="checked"
            :value="commodity"
          />
          <v-list-item-title>{{ commodity.name }}</v-list-item-title>
        </v-list-item>
      </g-dialog>
    </v-form>
    <div v-if="fromDashboard">
      <v-divider class="mt-4 pb-2" />
      <v-row
        class="pt-4 pb-2"
        align-center
      >
        <p class="text-body-1 grey--text pr-10 pl-5 mt-2">
          Commodities on your Farm Profile
        </p>
        <v-btn
          v-if="!$isMobile"
          class="ml-14 text-body-2 primary--text font-weight-bold"
          color="transparent"
          href="/farm_profile/commodities"
          @click="closeDialog"
        >
          Go to Farm Profile
        </v-btn>
      </v-row>
      <div
        ref="desktopChipRow"
        :class="{'desktop-row-max' : toggleMoreButton && !$isMobile && !showAllCrops,
                 'desktop-row-fixed': !$isMobile && showAllCrops && commodityBank.length }"
      >
        <div
          ref="mobileChipRow"
          :class="{'mobile-row-max' : $isMobile}"
        >
          <v-chip
            v-for="(commodity, rank) in farmProfileCommodities"
            :key="rank"
            :small="!$isMobile"
            class="my-1 mx-1 mb-3"
            disabled
            :class="{'mr-2 mt-2 mb-2' : $isMobile}"
          >
            {{ commodity.name }}
          </v-chip>
        </div>
      </div>
      <v-btn
        v-if="$isMobile"
        class="ml-14 text-body-2 primary--text font-weight-bold"
        color="transparent"
        href="/farm_profile/commodities"
        @click="closeDialog"
      >
        Go to Farm Profile
      </v-btn>
    </div>
  </component>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import GDialog from '../generic/GDialog.vue';

export default {

  components: {
    GDialog,
  },

  props: {
    cropData: {
      type: Array,
      required: true,
    },
    preSelectedCrops: {
      type: Array,
      default: () => [],
    },
    showAllCrops: {
      type: Boolean,
      required: false,
      default: false,
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      commodityBank: [],
      selectedCommodities: [],
      mobileShowMore: false,
      toggleMoreButton: true,
      checked: [],
      displayRowExpansion: true,
      comboBoxRules: [
        () => !this.selectedCommodities.length <= 0 || 'Select at least one crop interest to proceed',
      ],
      actionButtons: [
        {
          name: 'Cancel',
          type: 'default',
          onClick: this.closeMobileShowMore,
        },
        {
          name: 'Add',
          type: 'primary',
          onClick: this.addToSelectedCommodities,
        },
      ],
      CommoditiesOfInterestActionButtons: [
        {
          name: 'Cancel',
          type: 'default',
          onClick: this.closeDialog,
          side: 'right',
        },
        {
          name: 'Save',
          type: 'primary',
          onClick: this.handleSave,
          side: 'right',
        },
      ],
      // These are all the commodities we have which should exist
      // The defined order of each commodity in 'listOfCommodities' matters
      // since this is the order defined by Mike Reimer,
      // and the order which shows up in the component
      listOfCommodities: [
        'Barley', 'Durum', 'Alfalfa', 'Wheat', 'Beans', 'Canola',
        'Corn', 'Flax', 'Canaryseed', 'Oats', 'Soybeans', 'Chickpeas',
        'Lentils', 'Peas', 'Rye', 'Camelina', 'Mustard', 'Dry Beans',
        'Safflower', 'Caraway', 'Milo', 'Buckwheat', 'Fababeans', 'Coriander',
        'Summerfallow', 'Triticale', 'Quinoa', 'Millet', 'Mustard Oriental',
        'Sunflower',
      ],
    };
  },

  computed: {
    ...mapGetters('shared', ['parentCommodityList']),
    ...mapState('farmProfile/salesRecommendations', ['commoditiesOfInterestDialogOpen', 'cropInterests', 'farmProfileCommoditiesList']),

    dialogOpen: {
      get() {
        return this.commoditiesOfInterestDialogOpen;
      },
      set(value) {
        this.setCommoditiesOfInterestDialogOpen(value);
      },
    },

    component() {
      if (this.fromDashboard) {
        return 'g-dialog';
      }

      return 'div';
    },

    farmProfileCommodities() {
      const commodities = this.farmProfileCommoditiesList
        .map((commodity) => this.parentCommodityList(commodity.id, true, true))
        .flat()
        .filter((commodity) => this.listOfCommodities.includes(commodity.name));
      return [...new Set(commodities)];
    },

    flattenFarmProfileCommodities() {
      return this.farmProfileCommodities.map((commodity) => commodity.name);
    },

    commodities() {
      const commodities = this.cropData;
      // In case cropData has commodities that are not in list of Commodities,
      // this will automatically add them to the end
      commodities.forEach((commodity) => {
        if (!this.listOfCommodities.includes(commodity)) {
          this.listOfCommodities.push(commodity);
        }
      });
      commodities.sort((a, b) => this.listOfCommodities.indexOf(a) - this.listOfCommodities.indexOf(b));
      if (this.fromDashboard) {
        const farmProfileCommoditiesNames = this.flattenFarmProfileCommodities;
        const result = commodities.filter((item) => !farmProfileCommoditiesNames.includes(item));
        const selectedCommoditiesNames = result.filter((item) => this.cropInterests.includes(item));
        this.updateSelectedCommodities(selectedCommoditiesNames);
        return result.filter((item) => !selectedCommoditiesNames.includes(item));
      }
      return commodities;
    },
  },

  watch: {
    selectedCommodities() {
      this.$nextTick(() => {
        this.isOverflow();
      });
    },
  },

  async mounted() {
    if (this.fromDashboard) {
      await this.getCropInterests();
      await this.getFarmprofileCommodities();
    }
    this.commodityBank = this.addRank(this.commodities);
    if (this.preSelectedCrops !== null && this.preSelectedCrops.length > 0) {
      this.preSelectedCrops.forEach(this.selectCommodity);
    }
  },

  methods: {
    ...mapMutations('farmProfile/salesRecommendations', ['setCommoditiesOfInterestDialogOpen']),
    ...mapActions('farmProfile/salesRecommendations', ['getFarmprofileCommodities', 'getCropInterests', 'updateCropInterests']),

    selectCommodity(commodity) {
      this.selectedCommodities.push(commodity);
      this.commodityBank = this.commodityBank.filter((rank) => rank.rank !== commodity.rank);
      this.$emit('cropSelection', this.selectedCommodities);
    },

    deselectCommodity(commodity) {
      this.selectedCommodities = this.selectedCommodities.filter((rank) => (
        rank.rank !== commodity.rank
      ));
      this.commodityBank.push(commodity);
      this.commodityBank.sort((a, b) => a.rank - b.rank);
      this.displayRowExpansion = this.isOverflow();
      this.$emit('cropSelection', this.selectedCommodities);
    },

    toggleMore() {
      this.toggleMoreButton = false;
    },
    toggleLess() {
      this.toggleMoreButton = true;
    },

    openDialog() {
      this.dialogOpen = true;
    },

    closeDialog() {
      this.dialogOpen = false;
    },

    openMobileShowMore() {
      this.mobileShowMore = true;
    },

    closeMobileShowMore() {
      this.mobileShowMore = false;
    },

    async handleSave() {
      const selectedCommoditiesNames = this.selectedCommodities.map((commodity) => commodity.name);
      const toKeep = this.cropInterests.filter((commodity) => this.flattenFarmProfileCommodities.includes(commodity));

      this.updateCropInterests({ crop_interests: [...selectedCommoditiesNames, ...toKeep].join(',') });
      if (this.fromDashboard) {
        await this.getFarmprofileCommodities();
        await this.getCropInterests();
      }
      this.dialogOpen = false;
    },

    addToSelectedCommodities() {
      this.checked.forEach((checkedCommodity) => {
        this.selectCommodity(checkedCommodity);
      });
      this.closeMobileShowMore();
      this.checked = [];
    },

    isOverflow() {
      const currentDesktopHeight = this.$refs.desktopChipRow?.offsetHeight;
      const hiddenDesktopHeight = this.$refs.desktopChipRow?.scrollHeight;

      const currentMobileHeight = this.$refs.mobileChipRow?.offsetHeight;
      const hiddenMobileHeight = this.$refs.mobileChipRow?.scrollHeight;
      // accounts for any commodities which can distort screen heights
      const MARGIN_OF_ERROR = 10;

      if (!this.$isMobile && (this.toggleMoreButton && !this.showAllCrops)
      && (currentDesktopHeight + MARGIN_OF_ERROR >= hiddenDesktopHeight)) {
        this.displayRowExpansion = false;
      } else if ((!this.toggleMoreButton && !this.showAllCrops) && hiddenDesktopHeight <= 65) {
        this.displayRowExpansion = false;
      } else if (this.$isMobile && currentMobileHeight + MARGIN_OF_ERROR >= hiddenMobileHeight) {
        this.displayRowExpansion = false;
      } else this.displayRowExpansion = true;
    },

    updateSelectedCommodities(selectedCommoditiesNames) {
      this.selectedCommodities = this.addRank(selectedCommoditiesNames);
    },

    addRank(commodities) {
      const rankedCommodities = commodities.map((commodity, index) => (
        {
          name: commodity,
          rank: index,
        }
      ));

      return rankedCommodities;
    },
  },
};
</script>

<style lang="scss">
.capped > div.v-input__control > div.v-input__slot > div.v-select__slot {
  > div.v-select__selections {
    overflow: auto;
    margin-top: 1px;
  }
}

.desktop-row-max {
  max-height: 65px;
  overflow: hidden;
}

.desktop-row-fixed {
  max-height: 120px;
  min-height: 0px !important;
  overflow: auto;
}

.mobile-row-max {
  max-height: 92px;
  overflow: hidden;
}

.fixed-form {
  height: 272px;
}
</style>
