<template>
  <v-overlay
    v-if="value"
    :z-index="2"
    :dark="false"
    :opacity="0.7"
    color="#ffffff"
    light
  >
    <div class="pa-4">
      <v-card
        class="mx-auto"
        max-width="564"
      >
        <v-card-text class="text-center pa-4 pa-md-6">
          <EmptyStateError
            style="width:100px;height:80px;"
            class="mb-2"
          />
          <div class="text-h5">
            <p class="mb-0">
              Uh oh!
            </p>
            <p>{{ featureName }} has encountered an issue.</p>
          </div>
          <div>
            <p class="mb-0">
              Please try again later.
            </p>
            <p>
              Contact
              <a href="mailto:support@farmlinksolutions.ca">support@farmlinksolutions.ca</a>
              if you have questions.
            </p>
            <p v-if="additionalMessage && featureName === 'Smart Advisor'">
              {{ additionalMessage }}
            </p>
          </div>
          <v-btn
            href="/dashboard/"
            color="primary"
          >
            Back to Dashboard
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
  </v-overlay>
</template>

<script>
import fetchIntercept from 'fetch-intercept';
import { mapState } from 'vuex';
import { datadogRum } from '@datadog/browser-rum';
import criticalEndpoints from './criticalEndpoints';
// Import the icon directly in case a network outage prevents icon from loading asynchronously
import EmptyStateError from '@/assets/icons/empty-state-error.svg';

export default {
  name: 'OutageOverlay',

  components: {
    EmptyStateError,
  },

  data() {
    return {
      value: false,
      featureName: 'GrainFox',
    };
  },

  computed: {
    ...mapState('launchDarkly', ['flags']),
    additionalMessage() {
      return this.flags['smart-advisor-maintenance-message'];
    },
  },

  mounted() {
    fetchIntercept.register({
      /**
       * Intercept all responses and check for failures on critical endpoints.
       * Status 401 is permitted with the assumption that the request credentials
       * are just stale and the token will be renewed immediately thereafter.
       * @param {FetchInterceptorResponse} response The response
       */
      response: (response) => {
        if (!response.ok && response.status !== 401) {
          this.checkEndpoint(response.url);
        }
        return response;
      },

      /**
       * Intercept all response/fetch errors and check for failures on critical endpoints.
       * Generally, these will be errors with fetch itself, like a network connectivity issue.
       * @param {any} error The error
       */
      responseError: (error) => {
        this.checkEndpoint(error.request.url);
        return Promise.reject(error);
      },
    });
  },

  methods: {
    /**
     * Checks whether we're viewing the page for a feature that tracks critical failures,
     * then checks whether the endpoint that failed is a critical endpoint. If so, then
     * we enable the outage overlay and log an error.
     * @param {string} url The request URL
     */
    checkEndpoint(url) {
      const routeMeta = this.$route?.matched?.[0]?.meta ?? {};
      if (routeMeta?.scope in criticalEndpoints) {
        const requestURL = new URL(url);
        const { origin, pathname } = requestURL;
        const criticalEndpointsByOrigin = criticalEndpoints[routeMeta.scope];
        const endpoints = criticalEndpointsByOrigin?.[origin] ?? [];
        if (endpoints.includes(pathname)) {
          this.enableOverlay(routeMeta.title);
          this.logError(routeMeta.title, requestURL);
        }
      }
    },

    /**
     * Enables the outage overlay
     * @param {string} featureName The name of the impacted feature
     */
    enableOverlay(featureName) {
      this.featureName = featureName;
      this.value = true;
    },

    /**
     * Logs info about the failure
     * @param {string} featureName The name of the impacted feature
     * @param {string} url The request url
     */
    logError(featureName, url) {
      datadogRum.addError(new Error(`A request to ${url} has failed, causing an outage in ${featureName}.`));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-overlay__content {
  width: 100%;
}
</style>
