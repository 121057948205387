import colors from '@/colors/colors.module.scss';

export const TINY_FONTFORMATS = [
  'Roboto=Roboto,sans-serif',
];

export const TINY_PLUGINS = [
  'advlist',
  'anchor',
  'autolink',
  'charmap',
  'code',
  'emoticons',
  'editimage',
  'fullscreen',
  'help',
  'image',
  'importcss',
  'link',
  'lists',
  'media',
  'nonbreaking',
  'pagebreak',
  'powerpaste',
  'preview',
  'quickbars',
  'save',
  'searchreplace',
  'table',
  'template',
  'visualblocks',
  'visualchars',
  'wordcount',
  'tableofcontents',
];

export const TINY_MENUBAR = [
  'file',
  'edit',
  'view',
  'insert',
  'format',
  'tools',
  'table',
  'help',
];

export const TINY_TOOLBAR = [
  'template',
  '|',
  'tableofcontents',
  '|',
  'undo',
  'redo',
  '|',
  'speak',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'fontsizeselect',
  'formatselect',
  '|',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
  '|',
  'outdent',
  'indent',
  '|',
  'numlist',
  'bullist',
  '|',
  'forecolor',
  'backcolor',
  'removeformat',
  '|',
  'insertfile',
  'image',
  'media',
];

export const TINY_QUICKBAR = [
  'bold',
  'italic',
  '|',
  'quicklink',
  'h2',
  'h3',
  'blockquote',
  'quickimage',
  'quicktable',
  '|',
  'speak',
];

export const TINY_INIT = {
  branding: false,
  content_style: `body { font-family: Roboto,sans-serif; background: ${colors.$greyLighten5} } .mce-content-body .g-layout__container { margin: -1rem;width: calc(100% + 2rem); }`,
  font_formats: TINY_FONTFORMATS.join('; '),
  image_advtab: true,
  image_caption: true,
  paste_data_images: true,
  powerpaste_googledocs_import: 'clean',
  powerpaste_word_import: 'clean',
  quickbars_selection_toolbar: TINY_QUICKBAR.join(' '),
  quickbars_image_toolbar: 'alignleft aligncenter alignright | speak',
  menubar: TINY_MENUBAR.join(' '),
  resize: false,
  toolbar: TINY_TOOLBAR.join(' '),
  toolbar_mode: 'floating',
  toolbar_sticky: true,
  visual: false,
  contextmenu: false,
  browser_spellcheck: true,
  extended_valid_elements: 'span[*],link[href|rel]',
  custom_elements: 'link',
  noneditable_editable_class: 'mceEditable',
  automatic_uploads: false,
  images_upload_handler(blobInfo, success) {
    success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
  },
};

export const LINK_INIT = {
  link_assume_external_targets: true,
  link_default_protocol: 'https',
  default_link_target: '_blank',
  relative_urls: false,
  convert_urls: false,
  link_title: false,
  target_list: [
    { title: 'New window', value: '_blank' },
  ],
  paste_postprocess(_, args) {
    [...args.node.querySelectorAll('a[href]')].forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener');
    });
  },
};
